'use client';

import { useAnalytics } from '@/hooks/useAnalytics';
import { useDisclosure } from '@/hooks/useDisclosure';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useExitIntent } from 'use-exit-intent';
import LogoStrattt from '../../public/logos/stratt-logo-full.svg';

const ExitIntentManager = () => {
    const { toggle, open, close, isOpen } = useDisclosure(false);
    const { registerHandler, unsubscribe } = useExitIntent({
        cookie: {
            daysToExpire: 20,
            key: 'strattt-exit-intent'
        },
        desktop: {
            triggerOnIdle: false,
            triggerOnMouseLeave: true,
            delayInSecondsToTrigger: 5 //TODO_LT : delete bc only works with triggerOnIdle
        },
        mobile: {
            triggerOnIdle: true,
            delayInSecondsToTrigger: 5
        }
    });

    registerHandler({
        id: 'openModal',
        handler: () => open()
    });

    // TODO_LT : remove because useless ?
    registerHandler({
        id: 'unsubscribe',
        handler: () => {
            // Handler
        },
        context: ['onUnsubscribe']
    });

    const { identify, track } = useAnalytics();

    const [email, setEmail] = useState('');

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        await identify(email);
        await track('SUBSCRIBED_TO_NEWSLETTER');
        unsubscribe();
        close();
    };

    return (
        <Dialog
            open={isOpen}
            transition
            className="fixed inset-0 z-50 flex w-screen items-center justify-center bg-black/50 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
            onClose={() => {
                unsubscribe();
                close();
            }}
        >
            <DialogPanel className="w-[98%] transform overflow-hidden rounded-lg bg-white px-4 py-6 text-left align-middle shadow-xl transition-all xs:px-5 sm:w-3/4 sm:px-6 lg:w-1/2">
                <LogoStrattt className={clsx('w-36', 'text-cyprus-default')} />
                <DialogTitle
                    as="h3"
                    className="my-6 font-title text-2xl font-medium leading-6 text-gray-900"
                >
                    Vous vous en allez déjà ?
                </DialogTitle>
                <div className="my-2">
                    <p className="inline text-sm text-gray-500">
                        Entrez votre adresse e-mail et rejoignez la{' '}
                        <strong>Strattt</strong>letter, déjà lue par plus de 600
                        entrepreneurs ! 100% Conseils, 100% Astuces, 0% Charabia
                        ! Promis on ne vous spamera pas (et on ne revend pas non
                        plus vos emails) !
                    </p>
                    <form
                        className={
                            'mt-4 flex flex-col items-center gap-y-4 rounded border-gray-500 sm:flex-row sm:border sm:p-2'
                        }
                        onSubmit={submitHandler}
                    >
                        <input
                            type={'email'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete={'email'}
                            className={
                                'w-full flex-1 rounded border border-gray-400/80 bg-cyprus-default/15 p-2 py-3 outline-none focus:ring-0 sm:w-auto sm:border-0 sm:bg-transparent sm:p-0'
                            }
                            placeholder={'vous@votreemail.com'}
                        />
                        <button
                            type={'submit'}
                            className={
                                'w-full rounded bg-cyprus-default px-4 py-2 font-title text-white sm:w-auto sm:bg-cyprus-default/25 sm:text-cyprus-default sm:hover:bg-cyprus-default/30'
                            }
                        >
                            S'inscrire
                        </button>
                    </form>
                </div>
            </DialogPanel>
        </Dialog>
    );
};

export default ExitIntentManager;
