'use client';

import { useAnalytics } from '@/hooks/useAnalytics';
/* import { analytics } from '@/lib/segment'; */
import { usePathname /* useSearchParams */ } from 'next/navigation';
import { useEffect } from 'react';

export default function Analytics() {
    const pathname = usePathname();
    //const searchParams = useSearchParams();
    const { analytics } = useAnalytics();

    useEffect(() => {
        if (analytics) {
            analytics.page().then();
        }
    }, [pathname /* searchParams */]);

    return null;
}
