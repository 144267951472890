'use client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import KSUID from 'ksuid';

//NOTE_DOC_LT : https://github.com/segmentio/analytics-next/tree/master/packages/browser#readme + https://github.com/vercel/next.js/blob/canary/examples/with-segment-analytics/components/analytics.tsx

export function useAnalytics() {
    /* Originally, was loaded in two different places (/lib/segment) */
    const analytics = /* isDevOrStaging()
        ? null
        : */ AnalyticsBrowser.load({
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string
    });

    const getId = async () => {
        if (analytics) {
            const user = await analytics.user();
            if (user.id()) {
                return user.id();
            } else {
                return 'client_' + KSUID.randomSync().string;
            }
        }
    };

    /*
    NOTE_LT : DID NOT WORK BY SIMPLY IMPORTING EXISTING
    useEffect(() => {
        if (analytics) {
            analytics.page().then();
        }
    }, []); */

    const identify = async (email: string, name?: string) => {
        if (analytics) {
            const id = await getId();
            await analytics.identify(id, { email, name });
        }
    };

    const track = async (event: string, params?: Record<string, any>) => {
        if (analytics) {
            await analytics.track(event, params).then();
        }
    };

    return { analytics, track, identify };
}
